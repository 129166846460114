import React, { FunctionComponent, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Layout from '../components/layout';
import { BookHero } from '../components/header/bookHero';
import { PageSection } from '../components/pageSection';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { IconImage } from '../components/iconImage';
import { Footer } from '../components/sections/footer';
import { SiteLogo } from '../components/header/logo/siteLogo';

const ImprintPage: FunctionComponent = () => {
  return (
    <Layout hideVideoBanner={true} title={'Impressum'}>
      <SiteLogo classNames={'standalone'} />
      <div className={'content-container no-banner first'}>
        <PageSection title={'Impressum'} id={'privacy'}>
          <div className="impressum">
            <p>Angaben gemäß § 5 TMG</p>
            <p>
              <strong>Vertreten durch: </strong>
              <br />
              Fabienne Herion
              <br />
              Philipp Christoph
              <br />
            </p>
            <p>
              <strong>Kontakt:</strong>
              <br />
              E-Mail: kontakt<span style={{ display: 'none' }}>shouldnotinterestyou</span>@fabienne-herion.de
            </p>
            <p>
              <strong>Webdesign durch: </strong>
              <br />
              Celestial Static Games (Philipp Christoph)
              <br />
              <a href={'https://celestial-static-games.de'}>celestial-static-games.de</a>
              <br />
            </p>
            <p>
              <strong>Haftungsausschluss: </strong>
              <br />
              <br />
              <strong>Haftung für Inhalte</strong>
              <br />
              <br />
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
              Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
              Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
              10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
              hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
              Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
              werden wir diese Inhalte umgehend entfernen.
              <br />
              <br />
              <strong>Haftung für Links</strong>
              <br />
              <br />
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
              Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
              Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
              Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
              <br />
              <br />
              <strong>Urheberrecht</strong>
              <br />
              <br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
              Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
              beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
              von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
            <p>
              Website Impressum von <a href="https://www.impressum-generator.de">impressum-generator.de</a>
            </p>
          </div>
        </PageSection>
        <Footer />
      </div>
    </Layout>
  );
};

export default ImprintPage;
